<template>
  <b-modal
    v-model="allocateStockModal.modalStatus"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    title="Tahsis Stok Eşleştir"
    size="lg"
    centered
  >
    <div>
      <div class="font-weight-bold text-primary">
        {{ allocateStockModal.stock.brand }} {{ allocateStockModal.stock.model }}
      </div>
      <div class="text-muted font-small-2">
        {{ allocateStockModal.stock.arac_cinsi }}
      </div>
      <div class="text-danger font-small-2">
        {{ allocateStockModal.stock.sase }} - {{ allocateStockModal.stock.motor_no }}
      </div>
      <div class="text-body font-weight-bold font-small-2">
        {{ allocateStockModal.stock.model_yili }}
      </div>
    </div>
    <BTable
      :fields="fields"
      :items="allocateStockModal.dataList"
      bordered
      small
      class="mt-1"
    >
      <template #cell(brand)="data">
        <div>{{ data.item.brand }} {{ data.item.model }}</div>
        <div class="font-small-2 text-warning">
          Renk: {{ data.item.color }} - Model Yılı: {{ data.item.myear }}
        </div>
        <div class="text-muted font-small-2">
          {{ data.item.hardware }}
        </div>
        <div class="text-info font-small-2">
          {{ data.item.username }}
        </div>
      </template>
      <template #cell(order_number)="data">
        <div v-if="data.item.id_com_sale_orderform">
          <b-link
            :href="'/sale_orderforms/view/' + data.item.order_number"
            target="_blank"
            variant="danger"
          >
            {{ data.item.order_number }}
          </b-link>
          <div class="text-danger font-small-2 font-weight-bold">
            {{ data.item.sale_username }}
          </div>
        </div>
        <div v-else>
          -
        </div>
      </template>
      <template #cell(control)="data">
        <b-button
          size="sm"
          block
          variant="success"
          pill
          :disabled="loading"
          @click="matchStock(data.item.id,allocateStockModal.stock.sase)"
        >
          <FeatherIcon icon="CheckCircleIcon" />
          Eşleştir
        </b-button>
      </template>
    </BTable>
  </b-modal>
</template>
<script>
import { BLink, BTable, BButton } from 'bootstrap-vue'

export default {
  name: 'AllocateStockModal',
  components: {
    BLink,
    BTable,
    BButton,
  },
  props: {
    getDataList: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          key: 'brand',
          label: 'Araç Bilgisi',
        },
        {
          key: 'order_number',
          label: 'SATIŞ DURUM',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'control',
          label: '#',
          thClass: 'width-100 text-nowrap text-center',
          tdClass: 'width-100 text-nowrap text-center',
        },
      ],
    }
  },
  computed: {
    allocateStockModal() {
      return this.$store.getters['stock/allocateStockModal']
    },
  },
  methods: {
    matchStock(id, chasis) {
      this.loading = true
      this.$store.dispatch('stock/matchStock', {
        id,
        chasis,
      })
        .then(response => {
          if (response.status) {
            this.$store.dispatch('stock/getAllocateStockList', {
              id_com_brand: this.allocateStockModal.stock.id_com_brand,
              myear: this.allocateStockModal.stock.model_yili,
              model: this.allocateStockModal.stock.model,
              getList: true,
            })
            this.getDataList()
          }
          this.loading = false
        })
    },
  },
}
</script>
